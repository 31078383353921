import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
const ContactMain = () => {
  const form = useRef();

  useEffect(() => {
    emailjs.init({
      publicKey: process.env.REACT_APP_EMAILJS_PUBLICKEY,
    });
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    const formData = form.current;
    emailjs
      .send("service_ttln9ji", "template_f07fhdk", {
        from_name: formData["user_name"].value ?? "",
        message: formData["message"].value ?? "",
        from_email: formData["user_email"].value ?? "",
        subject: formData["subject"].value ?? "",
      })
      .then(
        (result) => {
          if (result.text === "OK") {
            toast.success("Message Sent Successfully!");
            form.current[0].value = "";
            form.current[1].value = "";
            form.current[2].value = "";
            form.current[3].value = "";
          }
        },
        (error) => {
          if (error.text !== "OK") {
            toast.success("Message Not Sent!");
          }
        }
      );
  };
  return (
    <>
      {/* ================= Contact Main start =================*/}
      {/* <Toaster position="bottom-center" reverseOrder={false} /> */}
      <>
        {/* contact area start */}
        <div className="contact-area py-3">
          <div className="container">
            <div className="contact-page-inner bg-gray">
              <div className="section-title mb-4 pb-2">
                <h2 className="title">Direct contact us? </h2>
                <p className="content mb-0">
                  We believe in the power of communication and collaboration.
                  Whether you have a question about our services, need technical
                  support, or want to discuss a new project, our team is ready
                  to assist you. We are committed to providing exceptional
                  customer service and look forward to connecting with you.
                </p>
              </div>
              <form ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="single-input-inner">
                      <input
                        id="name"
                        name="user_name"
                        type="text"
                        placeholder="Enter Your Name."
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="single-input-inner">
                      <input
                        id="email"
                        name="user_email"
                        type="email"
                        placeholder="Enter Your Email."
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="single-input-inner">
                      <input
                        id="subject"
                        name="subject"
                        type="text"
                        placeholder="Enter Your Subject."
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="single-input-inner">
                      <textarea
                        name="message"
                        id="message"
                        cols="1"
                        rows="5"
                        placeholder="Enter Your Message ..."
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-base border-radius-5"
                      onClick={sendEmail}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* contact area end */}
        {/* contact list start */}
        <div className="contact-page-list">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="media single-contact-list">
                  <div className="media-left">
                    <img src="assets/icons/contact.png" alt="img" />
                  </div>
                  <a
                    href="https://wa.link/656vlz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="media-body">
                      <h5>Contacts us</h5>
                      <h6>+92300-9561164</h6>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="media single-contact-list">
                  <div className="media-left">
                    <img src="assets/icons/email.png" alt="img" />
                  </div>
                  <a href="mailto:info@civicsols.com">
                    <div className="media-body">
                      <h5> Email</h5>
                      <h6>info@cicivsols.com</h6>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="media single-contact-list">
                  <div className="media-left">
                    <img src="assets/icons/Location.png" alt="img" />
                  </div>
                  <div className="media-body">
                    <h5>Location</h5>
                    <h6>Lahore, Pakistan</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* contact list start */}
        {/* map start */}
        <div className="contact-g-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d29208.601361499546!2d90.3598076!3d23.7803374!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1589109092857!5m2!1sen!2sbd"
            title="new title"
          />
        </div>
        {/* map end */}
      </>

      {/* ================= Contact Main End =================*/}
    </>
  );
};

export default ContactMain;
