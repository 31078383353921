import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import ProjectDetails from "./pages/ProjectDetails";
import Contact from "./pages/Contact";
import HomeFour from "./pages/HomeFour";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import CustomScrollToTop from "./components/ScrollToTop";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import ProjectTwo from "./pages/ProjectTwo";
import { Toaster } from "react-hot-toast";
function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <BrowserRouter>
        <CustomScrollToTop/>
        <Routes>
          <Route exact path="/" element={<HomeFour />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/service" element={<Service />} />
          <Route exact path="/service-details" element={<ServiceDetails />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/team-details" element={<TeamDetails />} />
          <Route exact path="/project" element={<ProjectTwo />} />
          <Route exact path="/project-details" element={<ProjectDetails />} />
        </Routes>
        <ScrollToTop smooth color="#246BFD" />
      </BrowserRouter>
    </>
  );
}

export default App;
