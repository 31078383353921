import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const CounterAreaOne = () => {
  return (
    <>
      {/* counter area start */}
      <div
        className="counter-area bg-relative bg-cover py-5"
        style={{
          // backgroundImage: 'url("./assets/img/bg/5.png")',
          backgroundColor: "#101a29",
          paddingBlock: "40px",
          marginBottom: "40px",
          minHeight: "225px",
        }}
      >
        <div className="container pd-bottom-lg-90">
          <div className="row ">
            <div
              className="col-4 "
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <div className="single-counter-inner ps-lg-5">
                <div className="thumb" style={{ width: "70px" }}>
                  <img src="assets/icons/team.png" alt="img" />
                </div>
                <h3 className="text-white mb-1">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter">
                          <CountUp duration={5} delay={0} start={0} end={30} />{" "}
                          +
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h3>
                <p className="text-white">Team</p>
              </div>
            </div>
            <div
              className="col-4"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="single-counter-inner ps-lg-5">
                <div className="thumb" style={{ width: "70px" }}>
                  <img src="assets/icons/client.png" alt="img" />
                </div>
                <h3 className="text-white mb-1">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter">
                          <CountUp duration={5} delay={0} start={0} end={50} />{" "}
                          +
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h3>
                <p className="text-white">Clients</p>
              </div>
            </div>
            <div
              className="col-4"
              data-aos="fade-up"
              data-aos-delay="150"
              data-aos-duration="1500"
            >
              <div className="single-counter-inner ps-lg-5 ">
                <div className="thumb" style={{ width: "70px" }}>
                  <img src="assets/icons/portfolio.png" alt="img" />
                </div>
                <h3 className="text-white mb-1">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter">
                          <CountUp duration={5} delay={0} start={0} end={80} />{" "}
                          +
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h3>
                <p className="text-white">Project delivered</p>
              </div>
            </div>

            {/* <div
              className='col-lg-3 col-sm-6 '
              data-aos='fade-up'
              data-aos-delay='250'
              data-aos-duration='1500'
            >
              <div className='single-counter-inner'>
                <div className='thumb'>
                  <img src='assets/img/icon/8.svg' alt='img' />
                </div>
                <h2 className='text-white mt-4 mb-2'>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp duration={3} delay={0} start={0} end={230} />{" "}
                          +
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className='text-white'>Team member</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* counter area end */}
    </>
  );
};

export default CounterAreaOne;
