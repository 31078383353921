import React from "react";

const TeamAreaGroup = () => {
  return (
    <>
      {/* ================= TeamAreaGroup Start =================*/}
      <div className="team-area bg-relative py-3 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb" style={{ height: "320px" }}>
                  <img src="assets/images/sadeed.jpg" alt="img" />
                </div>
                <div className="details">
                  <h5>Sadeed ur Raheem</h5>
                  <p>CEO</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb" style={{ height: "320px" }}>
                  <img src="assets/images/person.png" alt="img" />
                </div>
                <div className="details">
                  <h5>Shajeel ur Rehman</h5>
                  <p>CTO</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb" style={{ height: "320px" }}>
                  <img src="assets/images/hamood.jpeg" alt="img" />
                </div>
                <div className="details">
                  <h5>Hamood ur Rehman</h5>
                  <p>Agile Project Manager</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb" style={{ height: "320px" }}>
                  <img src="assets/images/person.png" alt="img" />
                </div>
                <div className="details">
                  <h5>Talha Khaliq</h5>
                  <p>Sales & Growth Manager</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb" style={{ height: "320px" }}>
                  <img src="assets/images/usama.jpeg" alt="img" />
                </div>
                <div className="details">
                  <h5>Muhammad Usama</h5>
                  <p>Sales & Marketing Manager</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb" style={{ height: "320px" }}>
                  <img src="assets/images/waqar1.png" alt="img" />
                </div>
                <div className="details">
                  <h5>Waqar Afzal</h5>
                  <p>Business Development Manager</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb" style={{ height: "320px" }}>
                  <img src="assets/images/imran.jpg" alt="img" />
                </div>
                <div className="details">
                  <h5>Imran Ahmad</h5>
                  <p>Business Development Manager</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb" style={{ height: "320px" }}>
                  <img src="assets/images/person.png" alt="img" />
                  {/* <ul className="team-social-inner">
                    <li>
                      <Link to="#">
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaInstagram />
                      </Link>
                    </li>
                  </ul> */}
                </div>
                <div className="details">
                  <h5>Subhan Khan</h5>
                  <p>Technical Lead</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================= TeamAreaGroup End =================*/}
    </>
  );
};

export default TeamAreaGroup;
