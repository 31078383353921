import React from "react";
import {
  FaChevronRight,
  FaFacebookF,
  FaInstagram,
  FaEnvelope,
  FaPhoneAlt,
  FaLinkedin,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterThree = () => {
  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className="footer-area bg-black mt-0 pd-top-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-3  col-lg-4 col-md-6">
              <div className="widget widget_about">
                <div
                  className="thumb"
                  style={{
                    maxHeight: "95px",
                    marginTop: "-55px",
                    marginLeft: "-25px",
                  }}
                >
                  <img src="assets/img/logo-dark.png" alt="img" />
                </div>
                <div className="details">
                  <p>Your Vision, Our Expertise. </p>
                  <a
                    href="https://wa.link/656vlz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="mt-3">
                      <FaPhoneAlt /> +92300-9561164
                    </p>
                  </a>
                  <a href="mailto:info@civicsols.com">
                    <p className="mt-2">
                      <FaEnvelope /> info@civicsols.com
                    </p>
                  </a>
                  <ul className="social-media">
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.facebook.com/civicsols/"
                      >
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.linkedin.com/company/civicsols/"
                      >
                        <FaLinkedin />
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="http://www.instagram.com/civicsols"
                      >
                        <FaInstagram />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 ps-lg-5 mx-lg-auto col-lg-4 offset-xl-2 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Company</h4>
                <ul>
                  <li>
                    <Link to="/service">
                      <FaChevronRight /> Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/team">
                      <FaChevronRight /> Team
                    </Link>
                  </li>
                  <li>
                    <Link to="/project">
                      <FaChevronRight /> Portfolio
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <FaChevronRight /> About
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <FaChevronRight /> Contact us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 mx-lg-auto col-lg-4 offset-xl-2 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Our Service</h4>
                <ul>
                  <li>
                    <Link to="/service-details?comp=Web%20Development">
                      <FaChevronRight /> Web Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/service-details?comp=Mobile%20Development">
                      <FaChevronRight /> Mobile App Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/service-details?comp=Cloud%20Solutions">
                      <FaChevronRight /> Cloud Solution
                    </Link>
                  </li>
                  <li>
                    <Link to="/service-details?comp=Enterprise%20Solutions">
                      <FaChevronRight /> ERP Solution
                    </Link>
                  </li>

                  <li>
                    <Link to="/service-details?comp=Lead%20Generation">
                      <FaChevronRight /> Lead Generation
                    </Link>
                  </li>
                  <li>
                    <Link to="/service-details?comp=Digital%20Marketing">
                      <FaChevronRight /> Digital Marketing
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom bg-gray-black">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>CivicSols. </p>
              </div>
              <div className="col-md-6 text-lg-end">
                {/* <Link to="#">Trams &amp; Condition</Link>
                <Link to="#">Privacy Policy</Link>/ */}
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default FooterThree;
