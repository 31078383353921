import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom"; // Import NavLink for active link functionality

const NavbarThree = () => {
  const [active, setActive] = useState(false);

  const menuActive = () => {
    setActive(!active);
  };

  // Define main color for active state
  const mainColor = "#FF6A16"; // Replace with your main color

  return (
      <>
        {/* ==================== Navbar Two Start ====================*/}
        <nav className="navbar navbar-area navbar-area_3 navbar-area-2 fixed-top  navbar-expand-lg bg-white align-content-center ">
          <div className="container nav-container custom-container" style={{
              maxHeight: "100px", // Adjust the height of the navbar container
          }}>
            <div className="responsive-mobile-menu" >
              <button
                  onClick={menuActive}
                  className={
                    active
                        ? "menu toggle-btn d-block d-lg-none open"
                        : "menu toggle-btn d-block d-lg-none "
                  }
                  style={{ marginBlock: "32px" }}
                  data-target="#itech_main_menu"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
              >
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>
            <div className="logo">
              <Link to="/">
                <img
                    src="assets/img/logo.png"
                    alt="img"
                    style={{ maxWidth: "150px", height: "auto" }} // Adjust logo size without increasing navbar height
                />
              </Link>
            </div>

            <div
                className={
                  active
                      ? "collapse navbar-collapse sopen"
                      : "collapse navbar-collapse"
                }
                id="itech_main_menu"
            >
              <ul className="navbar-nav menu-open text-lg-end">
                {/* Inline style: Conditionally apply active color based on current route */}
                <li>
                  <NavLink
                      to="/"
                      exact
                      style={({ isActive }) => ({
                        color: isActive ? mainColor : "inherit", // Active link color
                        textDecoration: "none",
                      })}
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                      to="/service"
                      style={({ isActive }) => ({
                        color: isActive ? mainColor : "inherit", // Active link color
                        textDecoration: "none",
                      })}
                  >
                    Service
                  </NavLink>
                </li>
                <li>
                  <NavLink
                      to="/team"
                      style={({ isActive }) => ({
                        color: isActive ? mainColor : "inherit", // Active link color
                        textDecoration: "none",
                      })}
                  >
                    Team
                  </NavLink>
                </li>
                <li>
                  <NavLink
                      to="/project"
                      style={({ isActive }) => ({
                        color: isActive ? mainColor : "inherit", // Active link color
                        textDecoration: "none",
                      })}
                  >
                    Portfolio
                  </NavLink>
                </li>
                <li>
                  <NavLink
                      to="/about"
                      style={({ isActive }) => ({
                        color: isActive ? mainColor : "inherit", // Active link color
                        textDecoration: "none",
                      })}
                  >
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                      to="/contact"
                      style={({ isActive }) => ({
                        color: isActive ? mainColor : "inherit", // Active link color
                        textDecoration: "none",
                      })}
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="nav-right-part nav-right-part-desktop d-lg-inline-flex align-item-center">
              <Link className="btn btn-border-base" to="/contact">
                Request Quote
              </Link>
            </div>
          </div>
        </nav>
        {/* ==================== Navbar Two end ====================*/}
      </>
  );
};

export default NavbarThree;
